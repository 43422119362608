import * as React from "react"

interface ContoursProps{
  className: string,
  contourColour?: string
}
const Contours = ({className, contourColour = "#71F909"}: ContoursProps) => {
  return (
    <svg
      width={255}
      height={315}
      viewBox="0 0 255 315"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M170.333 1l-5.723 12.877a20 20 0 001.158 18.465l6.505 10.767a20.004 20.004 0 012.844 9.116l2.34 38.115a20.001 20.001 0 0013.997 17.864l27.812 8.691a20.003 20.003 0 0110.774 8.145l7.086 10.838a20.001 20.001 0 0016.74 9.055H255M1 70.122l1.95 1.302a15.635 15.635 0 0019.183-1.425v0a15.634 15.634 0 019.07-3.99l10.716-.987a20 20 0 0017.745-15.84l3.814-18.328a20 20 0 015.568-10.196l3.66-3.593a20 20 0 005.988-14.271V1.498M135.575 313.271l5.435-7.294c5.961-8.001 5.114-19.172-1.986-26.182l-5.465-5.394a20.002 20.002 0 01-5.843-12.185l-3.275-31.815a19.999 19.999 0 00-16.23-17.613l-55.694-10.382a19.994 19.994 0 01-9.814-4.886l-16.977-15.487a19.996 19.996 0 00-13.48-5.225H1"
        stroke={contourColour}
        strokeWidth={3}
      />
      <path
        d="M187.267 1.498l-3.758 8.484a19.999 19.999 0 001.152 18.415l3.662 6.083a19.997 19.997 0 012.829 9.094l1.649 26.942a19.998 19.998 0 0013.978 17.862l18.26 5.726a19.998 19.998 0 0110.772 8.165l4.406 6.762A17.645 17.645 0 00255 117.043v0M1 44.277l1.237.819a9.856 9.856 0 0012.035-.893v0a9.855 9.855 0 015.696-2.49l2.71-.247a18.046 18.046 0 0016.019-14.268l1.273-6.072a16.872 16.872 0 014.74-8.622l.772-.752a14.315 14.315 0 004.326-10.254v0M108.839 313.271l2.474-3.358c5.871-7.967 5.033-19.029-1.97-26.02l-1.168-1.165a20.003 20.003 0 01-5.768-12.129l-2.205-21.66a20 20 0 00-16.193-17.628l-40.578-7.648a20.006 20.006 0 01-9.856-4.953l-11.606-10.705a20.001 20.001 0 00-13.56-5.299H1"
        stroke={contourColour}
        strokeWidth={3}
      />
      <path
        d="M149.888 1.498l-7.154 16.019a20 20 0 001.165 18.534l8.57 14.116a19.998 19.998 0 012.865 9.147l2.847 46.135a20 20 0 0014.023 17.866l34.843 10.836a19.997 19.997 0 0110.775 8.116l9.57 14.566a20.002 20.002 0 0016.715 9.018H255M167.203 313.271l7.848-10.642c5.876-7.968 5.038-19.037-1.972-26.029l-8.958-8.937a19.997 19.997 0 01-5.772-12.132l-4.088-40.124a20 20 0 00-16.195-17.627l-66.424-12.511a19.995 19.995 0 01-9.853-4.948l-20.76-19.136a19.998 19.998 0 00-13.555-5.295H1"
        stroke={contourColour}
        strokeWidth={3}
      />
      <path
        d="M127.428 1.498l-9.586 21.437a20 20 0 001.167 18.552l12.094 19.895a20.008 20.008 0 012.871 9.156l3.699 59.877a20.001 20.001 0 0014.03 17.867l46.561 14.462a20.001 20.001 0 0110.776 8.108l13.718 20.853a20 20 0 0016.709 9.008H255M1 105.712l3.886 2.605a20 20 0 0024.605-1.83l1.494-1.361a19.998 19.998 0 0111.626-5.13l21.419-1.984a20 20 0 0017.74-15.857l7.038-33.966a20 20 0 015.542-10.183l9.265-9.136a20 20 0 005.958-14.241V1.498M203.139 313.271l11.741-15.908c5.883-7.971 5.044-19.048-1.973-26.042l-14.604-14.556a20.001 20.001 0 01-5.778-12.136l-5.453-53.478a19.998 19.998 0 00-16.198-17.626l-85.18-16.03a20 20 0 01-9.85-4.944l-27.403-25.239a20.004 20.004 0 00-13.55-5.288H1"
        stroke={contourColour}
        strokeWidth={3}
      />
      <circle
        cx={102.6}
        cy={119.035}
        r={45.316}
        transform="rotate(90 102.6 119.035)"
        fill="#fff"
        stroke={contourColour}
        strokeWidth={3}
      />
      <circle
        cx={222.627}
        cy={244.043}
        r={12.943}
        transform="rotate(90 222.627 244.043)"
        fill="#fff"
        stroke={contourColour}
        strokeWidth={3}
      />
      <circle
        cx={22.416}
        cy={265.957}
        r={12.943}
        transform="rotate(90 22.416 265.957)"
        fill="#fff"
        stroke={contourColour}
        strokeWidth={3}
      />
    </svg>
  )
}

export default Contours